import React from "react";
import { FaFileAlt, FaPaintBrush, FaCode, FaCheckCircle, FaRocket } from "react-icons/fa";

const Process = () => {
  const steps = [
    {
      icon: <FaFileAlt className="text-primary text-4xl" />,
      title: "Брифинг",
      description: "Обсуждаем Ваши цели, задачи и пожелания.",
    },
    {
      icon: <FaPaintBrush className="text-secondary text-4xl" />,
      title: "Дизайн",
      description: "Разрабатываем индивидуальный макет Вашего сайта.",
    },
    {
      icon: <FaCode className="text-primary-dark text-4xl" />,
      title: "Разработка",
      description: "Программируем сайт с использованием современных технологий.",
    },
    {
      icon: <FaCheckCircle className="text-secondary-dark text-4xl" />,
      title: "Тестирование",
      description: "Проверяем работу сайта на всех устройствах.",
    },
    {
      icon: <FaRocket className="text-green-500 text-4xl" />, // Цвет ракеты изменен на зеленый
      title: "Запуск",
      description: "Публикуем сайт и предоставляем техническую поддержку.",
    },
  ];

  return (
    <section className="py-16 text-center">
      <div className="container px-2">
        <h2 className="text-xl font-bold text-primary mb-4">Как мы работаем</h2>
        <p className="text-lg text-muted-secondary mb-8">
          Наш процесс работы максимально прозрачен и удобен для Вас.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 lg:gap-1">
          {steps.map((step, index) => (
            <div key={index} className="card relative p-4">
              <div className="absolute top-3 right-3 lg:top-2 lg:right-2 w-10 h-10 flex items-center justify-center border-2 border-primary rounded-full text-primary text-lg lg:text-base font-semibold">
                {index + 1}
              </div>
              {/* Иконка */}
              <div className="mb-2">{step.icon}</div>
              {/* Заголовок */}
              <h3 className="text-lg lg:text-base font-bold text-primary mb-2">{step.title}</h3>
              {/* Описание */}
              <p className="text-base text-muted">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Process;
