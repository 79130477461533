import React, { useState } from "react";
import Modal from "react-modal";

const ModalButton = ({
  buttonText = "Оставить заявку",
  buttonClassName = "button-primary",
  modalContent = null,
  modalClassName = "bg-[var(--color-bg)] mx-auto p-4 rounded-lg shadow-lg relative max-w-sm w-full",
  overlayClassName = "fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50",
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      {/* Кнопка открытия модального окна */}
      <button onClick={openModal} className={`px-6 py-3 font-semibold ${buttonClassName}`}>
        {buttonText}
      </button>

      {/* Модальное окно */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Модальное окно"
        className={modalClassName}
        overlayClassName={overlayClassName}
      >
        {/* Кнопка закрытия */}
        <button
          onClick={closeModal}
          className="absolute top-2 right-2 p-1 w-4 h-4 rounded-md bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 text-gray-500 hover:text-gray-700 transition transform scale-90"
          aria-label="Закрыть"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-full h-full"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        {/* Содержимое модального окна */}
        {modalContent && React.cloneElement(modalContent, { closeModal })}
      </Modal>
    </>
  );
};

export default ModalButton;
