import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import routes from '../routes';
import { useSelector, useDispatch } from 'react-redux';
import { toggleTheme } from '../store/themeSlice';
import { FaSun, FaMoon } from 'react-icons/fa';
import ModalButton from './ModalButton';
import LeadForm from './LeadForm';

function Header() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const theme = useSelector((state) => state.theme.theme);
  const dispatch = useDispatch();

  const location = useLocation();

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const toggleThemeHandler = () => {
    dispatch(toggleTheme());
  };

  return (
    <>
      {/* Шапка */}
      <header className="fixed top-0 w-full z-50 py-1 bg-white/10 dark:bg-black/10 border-b border-gray-300 dark:border-gray-700 shadow-md backdrop-blur-lg">
        <nav className="container flex justify-between items-center">
          {/* Логотип */}
          <h1 className="text-lg font-bold flex items-center">
            <Link to="/" className="hover:scale-105 transition-transform flex items-center">
              <span className="logo-symbol text-green-500">@</span>
              <span className="logo-k">K</span>
              <span className="logo-amoza">amoza</span>
              <span className="logo-kz ml-1">KZ</span>
            </Link>
          </h1>

          {/* Основное меню */}
          <ul className="hidden lg:flex space-x-3 mx-3">
            {routes
              .filter((route) => route.path !== '*' && route.title)
              .map(({ path, title }) => (
                <li key={path}>
                  <Link
                    to={path}
                    className={`text-base border-lg font-medium text-black tracking-wide dark:text-white ${
                      location.pathname === path
                        ? 'font-bold underline'
                        : ''
                    }`}
                  >
                    {title}
                  </Link>
                </li>
              ))}
          </ul>

          {/* Кнопка для открытия формы заявки */}
          <div className="hidden lg:block">
            <ModalButton
              buttonText="Оставить заявку"
              buttonClassName="button button-primary"
              modalContent={<LeadForm />}
            />
          </div>

          {/* Кнопка переключения темы */}
          <button
            onClick={toggleThemeHandler}
            className="hidden lg:block p-2 rounded-full bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 transition"
            aria-label="Toggle theme"
          >
            {theme === 'dark' ? <FaSun className="text-yellow-400" /> : <FaMoon className="text-gray-400" />}
          </button>

          {/* Кнопка мобильного меню */}
          <button
            onClick={toggleMobileMenu}
            className="lg:hidden p-1 rounded-md bg-gray-100 dark:bg-gray-800 shadow transition-transform duration-300 hover:scale-105"
            aria-label="Toggle mobile menu"
          >
            {isMobileMenuOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-3 h-3 text-gray-700 dark:text-gray-300"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-3 h-3 text-gray-700 dark:text-gray-300"
              >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          )}
        </button>
        </nav>
      </header>

      {/* Мобильное меню */}
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 z-40 bg-black bg-opacity-50 flex justify-end mt-5"
          onClick={closeMobileMenu} // Закрытие при клике вне меню
        >
          <div
            className={`w-2/3 max-w-xs bg-white dark:bg-black p-3 shadow-lg transform transition-transform duration-900 ${
              isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'
            }`}
            onClick={(e) => e.stopPropagation()} // Остановить всплытие события
          >
            {/* Ссылки меню */}
            <ul className="space-y-3 mb-4">
              {routes
                .filter((route) => route.path !== '*')
                .map(({ path, title }) => (
                  <li key={path}>
                    <Link
                      to={path}
                      onClick={closeMobileMenu}
                      className={`block text-base font-semibold transition text-black tracking-widest dark:text-white ${
                        location.pathname === path
                          ? 'underline'
                          : ''
                      }`}
                    >
                      {title}
                    </Link>
                  </li>
                ))}
            </ul>

            {/* Кнопка для открытия формы заявки */}
            <div className="flex justify-center">
              <ModalButton
                buttonText="Оставить заявку"
                buttonClassName="button button-primary"
                modalContent={<LeadForm />}
              />
            </div>     

            {/* Переключение темы */}
            <div className="fixed top-3 right-3">
              <button
                onClick={toggleThemeHandler}
                className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 transition"
                aria-label="Toggle theme"
              >
                {theme === 'dark' ? <FaSun className="text-yellow-400" /> : <FaMoon className="text-gray-400" />}
              </button>
            </div>

          </div>
        </div>
      )}

      {/* Размывание контента */}
      <div
        className={`fixed inset-0 z-30 transition-opacity ${
          isMobileMenuOpen ? 'bg-opacity-50 backdrop-blur-md pointer-events-none' : 'opacity-0 pointer-events-none'
        }`}
      />
    </>
  );
}

export default Header;
