import React from 'react';
import Services from '../components/Services';
import WhyChooseUs from '../components/WhyChooseUs';

const ServicesPage = () => {
  return (
    <section className="py-5">


      {/* Список услуг */}
      <Services />

      {/* Дополнительный блок */}
      <WhyChooseUs />
    </section>
  );
};

export default ServicesPage;
