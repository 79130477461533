import React from 'react';
import { Link } from 'react-router-dom';

function NotFoundPage() {
  return (
    <section className="container mx-auto py-5 px-6 md:px-12 lg:px-20 animate-fade-in text-center">
      {/* Заголовок страницы */}
      <div className="mb-12">
        <h1 className="text-6xl font-extrabold text-primary mb-4">404</h1>
        <p className="text-xl text-muted-secondary mb-6">
          К сожалению, страница, которую вы ищете, не существует.
        </p>
        <Link to="/" className="button button-primary text-lg">
          Вернуться на главную страницу
        </Link>
      </div>

      {/* Дополнительный контент */}
      <div className="mt-12 p-8 rounded-lg">
        <h2 className="text-3xl font-bold mb-4">Не нашли, что искали?</h2>
        <p className="text-muted">
          Попробуйте воспользоваться меню или свяжитесь с нами для получения помощи.
        </p>
      </div>
    </section>
  );
}

export default NotFoundPage;
