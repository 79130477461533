import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';

const RouteWithHelmet = ({
  title = 'Default Title',
  description = 'Default description',
  keywords = 'default, keywords',
  children,
}) => (
  <>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  </>
);

export default RouteWithHelmet;
