import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import ModalButton from './ModalButton';
import LeadForm from './LeadForm';

// Устанавливаем приложение для модального окна
Modal.setAppElement("#root");

const ServicesModal = ({ isOpen, onClose, title, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Модальное окно услуг"
      className="bg-[var(--color-bg)] mx-1 sm:mx-auto p-2 sm:p-3 md:p-4 rounded-lg shadow-lg relative max-w-sm w-full"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
    >
      <div className="flex justify-between items-center border-b border-gray-300 dark:border-gray-700">
        <h2 className="p-2 text-lg font-bold text-gray-800 dark:text-gray-100">{title}</h2>
        <button
          className="absolute top-2 right-2 p-1 w-4 h-4 rounded-md bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 text-gray-500 hover:text-gray-700 transition transform scale-90"
          onClick={onClose}
          aria-label="Закрыть"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-full h-full"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div className="text-gray-800 dark:text-gray-100">{children}</div>
      {/* Кнопка с модальным окном */}
      <div className="flex justify-center p-2">
        <ModalButton
            buttonText="Оставить заявку"
            buttonClassName="button button-primary animate-pulse hover:animate-none"
            modalContent={<LeadForm />}
        />
      </div>
    </Modal>
  );
};

ServicesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

ServicesModal.defaultProps = {
  title: "Услуга",
};

export default ServicesModal;
