import React from "react";

const SEOJsonLD = ({ jsonData }) => {
  return (
    <script type="application/ld+json">
      {JSON.stringify(jsonData)}
    </script>
  );
};

export default SEOJsonLD;
