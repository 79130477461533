import React from 'react';
import { FaTelegramPlane } from 'react-icons/fa';
import { FaWhatsapp } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import routes from '../routes';

function Footer() {
  const location = useLocation();

  return (
    <footer className="relative w-full py-10 px-2 bg-gradient-to-r from-blue-100 via-blue-50 to-blue-100 dark:from-gray-800 dark:via-gray-900 dark:to-gray-800">
      <div className="container grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
        {/* О компании */}
        <div>
          <h3 className="text-lg font-bold text-primary mb-4">О компании</h3>
          <p className="text-base text-muted">
            <span className="text-black dark:text-white">@Kamoza KZ</span> — Ваш партнёр в создании современных IT-решений.
          </p>
          <ul className="text-base text-muted space-y-2 mt-4">
            <li>• Разработка сайтов любой сложности</li>
            <li>• Telegram боты</li>
            <li>• Мобильные приложения</li>
          </ul>
        </div>

        {/* Контакты */}
        <div>
          <h3 className="text-lg font-bold text-primary mb-4">Контакты</h3>
          <ul className="text-base text-muted space-y-2">
            <li>
              <a href="mailto:info@kamoza.kz" className="hover:text-primary transition">
                📧 info@kamoza.kz
              </a>
            </li>
            <li>
              <a href="tel:+77018839715" className="hover:text-primary transition">
                📞 +7 701 883 97 15
              </a>
            </li>
            <li>📍 Казахстан, Астана, </li>
            <li className="pl-2"> пер. Култобе, 11</li>
          </ul>
          <div className="hidden lg:flex space-x-3 mt-3">
            {/* Telegram Button */}
            <a
              href="https://t.me/kamozaKZbot"
              target="_blank"
              rel="noreferrer"
              className="w-10 h-10 flex items-center justify-center border-2 rounded-full hover:scale-110 hover:text-blue-500 hover:text-blue-500transition-transform"
              aria-label="Telegram"
            >
              <FaTelegramPlane size={20} />
            </a>
            {/* WhatsApp Button */}
            <a
              href="https://wa.me/77018839715"
              target="_blank"
              rel="noreferrer"
              className="w-10 h-10 flex items-center justify-center border-2 rounded-full hover:scale-110 hover:text-green-600 transition-transform"
              aria-label="WhatsApp"
            >
              <FaWhatsapp size={20} />
            </a>
          </div>
        </div>

        {/* Меню */}
        <div>
          <h3 className="text-lg font-bold text-primary mb-4">Меню</h3>
          <ul className="text-base text-muted space-y-2">
            {routes
              .filter((route) => route.path !== '*' && route.title)
              .map(({ path, title }) => (
                <li key={path}>
                  <Link
                    to={path}
                    className={`block transition ${
                      location.pathname === path
                        ? 'text-primary underline'
                        : 'text-muted'
                    }`}
                  >
                    {title}
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </div>

      {/* <div className="p-8">
        <div className="transition-transform duration-300 ease-in-out origin-center">
          <img
            src="https://gogetssl-cdn.s3.eu-central-1.amazonaws.com/site-seals/gogetssl-static-seal.svg"
            width="105"
            height="34"
            title="GoGetSSL Site Seal, Protected website"
            alt="GoGetSSL Site Seal"
            className="hover-pulse"
          />
        </div>
      </div> */}
      
      {/* Копирайт */}
      <div className="text-center mt-4">
        <p className="text-base text-muted">
          «Делаем лучше!» —<br />
          <span className="text-black dark:text-white">@Kamoza KZ</span>
        </p>
        <p className="text-xs mt-3 text-muted-secondary">
          © {new Date().getFullYear()} ИП Kamoza. Все права защищены.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
