import React from 'react';
import Header from './Header';
import Footer from './Footer';
import TelegramButton from './TelegramButton';
import WhatsAppButton from './WhatsAppButton';
import CallButton from './CallButton';
import ScrollToTopButton from './ScrollToTopButton';

function Layout({ children }) {
  return (
    <div
      className="flex flex-col min-h-screen"
      style={{
        backgroundColor: 'var(--color-bg)',
        color: 'var(--color-text)',
        transition: 'background-color 0.3s ease, color 0.3s ease',
      }}
    >
      {/* Header */}
      <Header />
      <TelegramButton />
      <WhatsAppButton />
      <CallButton />
      <ScrollToTopButton />

      {/* Main content */}
      <main className="flex-grow pt-8 mt-3 lg:mt-4">
        {children}
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Layout;
