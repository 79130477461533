import React, { useState, useCallback } from "react";
import services from "./servicesList";

// Функция для получения CSRF-токена из cookie
function getCookie(name) {
  const cookies = document.cookie.split(";").map((cookie) => cookie.trim());
  const csrfCookie = cookies.find((cookie) => cookie.startsWith(`${name}=`));
  return csrfCookie ? decodeURIComponent(csrfCookie.split("=")[1]) : null;
}

const LeadForm = ({ onSuccess, closeModal }) => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    service: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // Валидация данных
  const validate = useCallback(() => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Введите ваше имя.";
    if (!/^\d{10,15}$/.test(formData.phone)) newErrors.phone = "Введите корректный номер телефона (10-15 цифр).";
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Введите корректный email.";
    if (!formData.service) newErrors.service = "Выберите услугу.";
    return newErrors;
  }, [formData]);

  // Обработчик изменения полей
  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" })); // Сброс ошибок для текущего поля
  }, []);

  // Отправка данных
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setIsSubmitting(true);

    try {
      const csrfToken = getCookie("csrftoken");
      const response = await fetch("/api/leads/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Ошибка на сервере:", errorData);
        alert("Ошибка на сервере. Попробуйте позже.");
        return;
      }

      const data = await response.json();
      setFormData({ name: "", phone: "", email: "", service: "", message: "" });
      if (onSuccess) onSuccess(data);

      setShowSuccessModal(true);
    } catch (error) {
      console.error("Ошибка отправки данных:", error);
      alert("Произошла ошибка. Попробуйте ещё раз.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Закрытие модального окна благодарности
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    if (typeof closeModal === "function") closeModal();
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="max-w-sm bg-[var(--color-bg)] p-0 rounded shadow space-y-1 md:space-y-2">
        <h2 className="text-lg font-bold text-gray-800 dark:text-gray-100">Оставить заявку</h2>

        {/* Имя */}
        <div>
          <label htmlFor="name" className="block text-base font-medium text-gray-800 dark:text-gray-100">
            Имя
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Ваше имя"
            className="w-full px-3 py-2 border rounded focus:ring focus:ring-blue-500"
            required
          />
          {errors.name && <p className="text-red-500 text-base">{errors.name}</p>}
        </div>

        {/* Телефон */}
        <div>
          <label htmlFor="phone" className="block text-base font-medium text-gray-800 dark:text-gray-100">
            Телефон
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="87018839715"
            className="w-full px-3 py-2 border rounded focus:ring focus:ring-blue-500"
            required
          />
          {errors.phone && <p className="text-red-500 text-base">{errors.phone}</p>}
        </div>

        {/* Email */}
        <div>
          <label htmlFor="email" className="block text-base font-medium text-gray-800 dark:text-gray-100">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="info@kamoza.kz"
            className="w-full px-3 py-2 border rounded focus:ring focus:ring-blue-500"
          />
          {errors.email && <p className="text-red-500 text-base">{errors.email}</p>}
        </div>

        {/* Услуга */}
        <div>
          <label
            htmlFor="service"
            className="block text-base font-medium text-gray-800 dark:text-gray-100"
          >
            Услуга
          </label>
          <select
            id="service"
            name="service"
            value={formData.service}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded focus:ring focus:ring-blue-500"
            required
          >
            <option value="">Не выбрана</option>
            {services.map((service, index) => (
              <option key={index} value={service.title}>
                {service.title}
              </option>
            ))}
          </select>
          {errors.service && (
            <p className="text-red-500 text-base">{errors.service}</p>
          )}
        </div>


        {/* Сообщение */}
        <div>
          <label htmlFor="message" className="block text-base font-medium text-gray-800 dark:text-gray-100">
            Сообщение
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Ваш комментарий"
            className="w-full px-3 py-2 border rounded focus:ring focus:ring-blue-500"
          ></textarea>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 disabled:bg-gray-400"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Отправка..." : "Отправить заявку"}
        </button>
      </form>

      {/* Модальное окно благодарности */}
      {showSuccessModal && (
        <div className="fixed p-1 inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white m-1 dark:bg-gray-700 p-6 rounded shadow-md text-center">
            <h3 className="text-lg font-bold text-gray-900 dark:text-gray-100 mb-2">Спасибо за Вашу заявку!</h3>
            <p className="text-base text-gray-800 dark:text-gray-300 mb-4">Мы свяжемся с вами в ближайшее время.</p>
            <button
              onClick={handleCloseSuccessModal}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Закрыть
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default LeadForm;
