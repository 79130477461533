import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  theme: localStorage.getItem('theme') || 'light', // Читаем тему из localStorage или ставим "light" по умолчанию
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme: (state) => {
      const newTheme = state.theme === 'light' ? 'dark' : 'light';
      state.theme = newTheme;
      localStorage.setItem('theme', newTheme); // Сохраняем в localStorage

      // Применяем класс темы
      document.documentElement.classList.remove('light', 'dark');
      document.documentElement.classList.add(newTheme);
    },
    setTheme: (state, action) => {
      const newTheme = action.payload;
      state.theme = newTheme;
      localStorage.setItem('theme', newTheme); // Сохраняем в localStorage

      // Применяем класс темы
      document.documentElement.classList.remove('light', 'dark');
      document.documentElement.classList.add(newTheme);
    },
    initializeTheme: (state) => {
      const savedTheme = localStorage.getItem('theme') || 'light';
      state.theme = savedTheme;

      // Применяем класс темы при загрузке
      document.documentElement.classList.remove('light', 'dark');
      document.documentElement.classList.add(savedTheme);
    },
  },
});

export const { toggleTheme, setTheme, initializeTheme } = themeSlice.actions;

export default themeSlice.reducer;
