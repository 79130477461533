import React from 'react';

function AboutPage() {
  return (
    <section className="container py-5 px-2 sm:px-4 md:px-16 animate-fade-in">
      {/* Заголовок */}
      <div className="text-center mb-12">
        <h1 className="text-xl font-extrabold text-primary mb-4">О нас</h1>
        <p className="text-lg text-muted-secondary">
        <span className="text-black dark:text-white">@Kamoza KZ</span> — это команда профессионалов, создающих современные сайты, Telegram-боты и
          мобильные приложения. Мы фокусируемся на уникальных решениях для каждого клиента.
        </p>
      </div>

      {/* Контент карточек */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="card">
          <h2 className="text-lg font-bold text-primary mb-2">Наша миссия</h2>
          <p className="text-base text-muted-secondary">
            Мы помогаем бизнесам расти, предоставляя инновационные цифровые решения.
          </p>
        </div>
        <div className="card">
          <h2 className="text-lg font-bold text-primary mb-2">Наши ценности</h2>
          <p className="text-base text-muted-secondary">
            Качество, надежность и индивидуальный подход — основные принципы нашей работы.
          </p>
        </div>
      </div>
    </section>
  );
}

export default AboutPage;
