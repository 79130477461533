import React, { useState } from "react";
import ServicesModal from "./ServicesModal";
import SEOJsonLD from "./SEO/SEOJsonLD";
import LeadForm from './LeadForm';
import ModalButton from "./ModalButton";
import services from "./servicesList";

const Services = () => {
  const [selectedService, setSelectedService] = useState(null);

  // Открытие модального окна для услуги
  const handleMoreInfo = (service) => {
    setSelectedService(service);
  };

  const closeServicesModal = () => {
    setSelectedService(null);
  };

  return (
    <>
      <SEOJsonLD jsonData={{
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "Услуги Kamoza KZ",
        "hasOfferCatalog": {
          "@type": "OfferCatalog",
          "name": "Наши услуги",
          "itemListElement": services.map((service) => ({
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": service.title,
              "description": service.description,
            },
            "priceSpecification": {
              "@type": "PriceSpecification",
              "priceCurrency": "KZT",
              "price": service.price.replace(" KZT", "").replace(",", ""),
            },
          })),
        },
      }} />
      <section className="text-center">
        <div className="container md:px-12 lg:px-20">
          {/* Заголовок услуг */}
          <div className="text-center mb-12">
            <h1 className="text-xl font-extrabold text-primary mb-4">Наши услуги</h1>
            <p className="text-lg text-muted-secondary">
              Мы предлагаем полный спектр услуг для Вашего бизнеса — от создания сайтов до интеграции с Telegram-ботами и мобильными приложениями.
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
            {services.map((service, index) => (
              <article
                key={index}
                className="card hover:shadow-lg hover:scale-105 transform transition-all duration-300"
              >
                <div className="text-4xl mb-2" aria-hidden="true">
                  {service.icon}
                </div>
                <h3 className="text-lg font-bold text-primary mb-2">
                  {service.title}
                </h3>
                <p className="text-base text-muted mb-2">{service.description}</p>
                <p className="text-lg font-bold text-secondary mb-3">{service.price}</p>
                <button
                  className="button button-primary mb-2 hover-pulse"
                  onClick={() => handleMoreInfo(service)}
                >
                  Подробнее
                </button>
              </article>
            ))}
          </div>
          <p className="text-base text-muted">
            Мы предоставляем профессиональные услуги, которые помогут Вашему бизнесу расти и развиваться. Свяжитесь с нами для консультации!
          </p>
        </div>
      </section>

      {/* Кнопка для открытия формы заявки */}
      <div className="text-center my-4">
        <ModalButton
          buttonText="Оставить заявку"
          buttonClassName="button button-primary animate-pulse hover:animate-none"
          modalContent={<LeadForm />}
        />
      </div>

      {/* Модальное окно услуг */}
      {selectedService && (
        <ServicesModal isOpen={!!selectedService} onClose={closeServicesModal} title={selectedService.title}>
          <div className="p-2">
            <h2 className="text-base sm:text-lg font-bold text-primary mb-2">
              {selectedService.details.heading || "Информация отсутствует"}
            </h2>
            <p className="text-base text-muted mb-2">
              {selectedService.details.description || "Описание отсутствует"}
            </p>
            <ul className="list-disc list-inside mb-2">
              {(selectedService.details.benefits || []).map((benefit, index) => (
                <li key={index}>{benefit}</li>
              ))}
            </ul>
            <p className="text-base font-semibold text-secondary">
              {selectedService.details.callToAction || "Данные отсутствуют"}
            </p>
          </div>
        </ServicesModal>
      )}
    </>
  );
};

export default Services;
