import React from 'react';
import { FaTelegramPlane } from 'react-icons/fa';
import { FaWhatsapp } from 'react-icons/fa';

function ContactPage() {
  return (
    <section className="container py-5 px-2 sm:px-4 md:px-8 animate-fade-in">
      {/* Заголовок */}
      <div className="text-center mb-4">
        <h1 className="text-xl font-extrabold text-primary mb-4">Контакты</h1>
        <p className="text-lg text-muted-secondary">
          Напишите или позвоните нам — мы всегда готовы помочь!
        </p>
      </div>

      {/* Контент */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Блок "Связаться с нами" */}
        <div className="card">
          <h2 className="text-lg font-bold text-primary mb-3">Связаться с нами</h2>
          <p className="text-base mb-2">
            📧 Email:{" "}
            <a href="mailto:info@kamoza.kz" className="text-primary hover:underline">
              info@kamoza.kz
            </a>
          </p>
          <p>
            📞 Телефон:{" "}
            <a href="tel:+77018839715" className="text-base text-primary hover:underline">
              +7 701 883 97 15
            </a>
          </p>
          <div className="hidden lg:flex space-x-3 mt-3">
            {/* Telegram Button */}
            <a
              href="https://t.me/kamozaKZbot"
              target="_blank"
              rel="noreferrer"
              className="w-10 h-10 flex items-center justify-center border-2 rounded-full hover:scale-110 hover:text-blue-500 hover:text-blue-500transition-transform"
              aria-label="Telegram"
            >
              <FaTelegramPlane size={20} />
            </a>

            {/* WhatsApp Button */}
            <a
              href="https://wa.me/77018839715"
              target="_blank"
              rel="noreferrer"
              className="w-10 h-10 flex items-center justify-center border-2 rounded-full hover:scale-110 hover:text-green-600 transition-transform"
              aria-label="WhatsApp"
            >
              <FaWhatsapp size={20} />
            </a>
          </div>
        </div>

        {/* Блок "Наш офис" */}
        <div className="card">
          <h2 className="text-lg font-bold text-primary mb-3">Наш офис</h2>
          <p>📍 Адрес: Казахстан, </p>
          <p>г.Астана, пер. Култобе, 11</p>
          <p className="mt-2 font-medium">Время работы:</p>
          <p>Пн – Пт: 09:00 – 18:00</p>
          <p>Сб: 10:00 – 14:00</p>
          <p>Вс: выходной</p>
        </div>
      </div>

        {/* Яндекс.Карта */}
        <h2 className="text-xl font-extrabold text-primary mb-4 text-center mt-8">Мы на карте</h2>

        <div className="mx-2 sm:mx-0 mt-2 md:mt-4">
          <iframe
            src="https://yandex.ru/map-widget/v1/?ll=71.450722%2C51.174915&z=16&l=map&pt=71.450722,51.174915,pm2rdl"
            className="w-full md:w-full h-60 md:h-96 lg:h-[500px] rounded-lg shadow-lg"
            frameBorder="0"
            allowFullScreen
            role="application"
            title="����� �����"
          ></iframe>
        </div>
    </section>
  );
}

export default ContactPage;
