const services = [
    {
      icon: <span role="img" aria-label="Сайт-визитка">📄</span>,
      title: "Сайт-визитка",
      description: "Простой способ представить себя и свои услуги в интернете.",
      price: "от 179 000 ₸",
      details: {
        heading: "Идеальный старт для Вашего бизнеса!",
        description: "Мы создаём минималистичные сайты-визитки...",
        benefits: ["Быстрая разработка (от 7 дней)", "SEO-оптимизация", "Мобильная адаптация"],
        callToAction: "Оставьте заявку и получите свой сайт уже через несколько дней!",
      },
    },
    {
      icon: <span role="img" aria-label="Корпоративный сайт">🏢</span>,
      title: "Корпоративный сайт",
      description: "Современный сайт с админкой и интеграцией с базами данных.",
      price: "от 299 000 ₸",
      details: {
        heading: "Профессиональный сайт для Вашего бизнеса.",
        description: "Масштабный сайт с индивидуальным дизайном, админкой и мощной функциональностью для работы с клиентами.",
        benefits: [
          "Интеграция с базами данных",
          "SEO для привлечения клиентов",
          "Разделы для партнёров и сотрудников",
        ],
        callToAction: "Давайте построим сайт, который будет работать на Ваш успех!",
      },
    },
    {
      icon: <span role="img" aria-label="Интернет-магазин">🛒</span>,
      title: "Интернет-магазин",
      description: "Полнофункциональный сайт с корзиной, оплатой и админкой.",
      price: "от 489 000 ₸",
      details: {
        heading: "Ваш магазин онлайн – быстро и удобно.",
        description: "Полнофункциональный сайт для продаж товаров с корзиной, онлайн-оплатой и удобной админкой для управления заказами.",
        benefits: [
          "Подключение платёжных систем",
          "Возможность интеграции с CRM",
          "Гибкие настройки каталога товаров",
        ],
        callToAction: "Начните продажи онлайн уже сегодня!",
      },
    },
    {
      icon: <span role="img" aria-label="Telegram-бот">🤖</span>,
      title: "Telegram-бот",
      description: "Автоматизация бизнес-процессов и взаимодействия через Telegram.",
      price: "от 149 000 ₸",
      details: {
        heading: "Ваш персональный помощник в Telegram.",
        description: "Создание удобного и функционального бота для автоматизации задач и общения с клиентами.",
        benefits: [
          "Умный чат-бот с автоответами",
          "Подключение аналитики и сбора данных",
          "Интеграция с вашей CRM или сайтом",
        ],
        callToAction: "Давайте автоматизируем ваш бизнес!",
      },
    },
    {
      icon: <span role="img" aria-label="Приложение">📱</span>,
      title: "Мобильное приложение",
      description: "Разработка современных приложений для iOS и Android.",
      price: "от 889 000 ₸",
      details: {
        heading: "Ваш бизнес в кармане у клиентов.",
        description: "Современные приложения для iOS и Android, которые помогают Вашему бизнесу быть ближе к клиентам.",
        benefits: [
          "Удобный и красивый дизайн",
          "Публикация в App Store и Google Play",
          "Интеграция с веб-сервисами",
        ],
        callToAction: "Запустите приложение, которое сделает Ваш бизнес доступным 24/7!",
      },
    },
    {
      icon: <span role="img" aria-label="Поддержка">🔧</span>,
      title: "Техническая поддержка",
      description: "Обеспечение стабильной работы Вашего сайта или сервиса.",
      price: "от 29 000 ₸",
      details: {
        heading: "Мы заботимся о стабильной работе Ваших сервисов.",
        description: "Постоянное обслуживание сайтов и сервисов для бесперебойной работы Вашего бизнеса.",
        benefits: [
          "Мониторинг доступности",
          "Быстрое исправление ошибок",
          "Регулярные обновления системы",
        ],
        callToAction: "Доверьте техническую сторону нам!",
      },
    },
  ];
  
  export default services;
  