import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    isVisible && (
      <button
        className="fixed bottom-3 lg:bottom-10 left-4 lg:left-1/2 transform -translate-x-1/2 z-50 w-12 sm:w-14 h-12 sm:h-14 flex items-center justify-center rounded-full border-2 shadow-lg transition duration-300"
        style={{
          backgroundColor: 'var(--color-bg)', // Цвет фона
          borderColor: 'var(--color-border)', // Граница
          color: 'var(--color-text-secondary)', // Цвет стрелки
          opacity: 0.7, // Прозрачность кнопки
        }}
        onClick={scrollToTop}
        aria-label="Scroll to top"
      >
        <FaArrowUp size={16} />
      </button>
    )
  );
};

export default ScrollToTopButton;
