import React from "react";
import { FaRocket, FaMobileAlt, FaBriefcase, FaLock } from "react-icons/fa";

const WhyChooseUs = () => {
  const features = [
    {
      icon: <FaRocket className="text-primary text-4xl" />,
      title: "Скорость",
      description: "Запуск Вашего сайта от 7 дней без потери качества.",
    },
    {
      icon: <FaMobileAlt className="text-secondary text-4xl" />,
      title: "Адаптивность",
      description: "Идеальная работа на любых устройствах — от смартфонов до ПК.",
    },
    {
      icon: <FaBriefcase className="text-primary-dark text-4xl" />,
      title: "Опыт",
      description: "Сильная техническая база и современные подходы для Вашего бизнеса.",
    },
    {
      icon: <FaLock className="text-secondary-dark text-4xl" />,
      title: "Надёжность",
      description: "Безопасность данных и стабильная работа Вашего сайта.",
    },
  ];

  return (
    <section className="py-5 text-center">
      <div className="container px-2 md:px-4 lg:px-8">
        <h2 className="text-xl font-bold text-primary mb-4">
          Почему выбирают нас?
        </h2>
        <p className="text-lg text-muted-secondary mb-4">
        Мы создаём решения, которые выделяют Вас среди конкурентов и помогают уверенно представить себя в интернете.
Надёжность, индивидуальный подход и скорость — вот что делает нашу работу по-настоящему эффективной.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {features.map((feature, index) => (
            <div
              key={index}
              className="card"
            >
              <div className="mb-2">{feature.icon}</div>
              <h3 className="text-lg font-bold text-primary mb-2">
                {feature.title}
              </h3>
              <p className="text-base text-muted">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
