import React from 'react';
import Services from '../components/Services';
import HeroSection from '../components/HeroSection';
import WhyChooseUs from '../components/WhyChooseUs';
// import Testimonials from '../components/Testimonials';
import Process from '../components/Process';

function HomePage() {
  return (
    <div>
      <HeroSection />
      <div className="divider mb-5"></div>
      <Services />
      <div className="divider"></div>
      <WhyChooseUs />
      <div className="divider"></div>
      {/* <Testimonials /> */}
      <Process />
    </div>
  );
}

export default HomePage;
